export default {
    beforeMount(el, binding) {
        el.classList.add('opacity-0', 'transform', 'transition-all', 'duration-1000', 'ease-in-out');

        const direction = binding.arg || 'up';
        let initialClass;

        switch (direction) {
            case 'up':
                initialClass = 'translate-y-9';
                break;
            case 'down':
                initialClass = '-translate-y-9';
                break;
            case 'left':
                initialClass = 'translate-x-9';
                break;
            case 'right':
                initialClass = '-translate-x-9';
                break;
            default:
                initialClass = 'translate-y-9';
        }

        el.classList.add(initialClass);

        const options = {
            root: null,
            threshold: 0.1,
        };

        const callback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    el.classList.remove('opacity-0', initialClass);
                    el.classList.add('opacity-100');
                } else {
                    el.classList.add('opacity-0', initialClass);
                    el.classList.remove('opacity-100');
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        observer.observe(el);
    },
};