<template>
  <div class="relative" @click="toggleDropdown">
    <button :class="['text-sm px-3 py-2 sm:px-4 sm:py-3 rounded-full', statusText === 'Geöffnet' ? 'bg-green-500 text-white' : 'bg-red-500 text-white']">
      <span class="hidden lg:inline-block">Öffnungszeiten | </span> {{ statusText }}
    </button>
    <div v-if="isOpen" class="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-20">
      <ul>
        <li v-for="(time, index) in openTimes" :key="index" class="px-4 py-2 text-sm text-gray-700">
          <strong>{{ time.day }}:</strong> {{ time.hours }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';

const isOpen = ref(false);
const openTimes = ref([
  {day: 'Montag', hours: '08:00 - 16:00'},
  {day: 'Dienstag', hours: '08:00 - 16:00'},
  {day: 'Mittwoch', hours: '08:00 - 16:00'},
  {day: 'Donnerstag', hours: '08:00 - 16:00'},
  {day: 'Freitag', hours: '08:00 - 16:00'},
  {day: 'Samstag', hours: 'Geschlossen'},
  {day: 'Sonntag', hours: 'Geschlossen'}
]);

const statusText = ref('');
const holidays = ref([]);
const currentDate = ref(new Date());

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const checkOpenStatus = () => {
  const now = new Date();
  const day = now.getDay(); // Sunday - Saturday : 0 - 6
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const currentTime = hours + minutes / 60;
  const currentDateStr = now.toISOString().split('T')[0];

  console.log(`Current time: ${currentTime}, Current day: ${day}, Current date: ${currentDateStr}`);

  // Check if it's a holiday
  const isHoliday = holidays.value.includes(currentDateStr);
  console.log(`Is holiday: ${isHoliday}`);

  let open = false;

  if (isHoliday) {
    open = false;
  } else {
    switch (day) {
      case 1: // Montag
      case 2: // Dienstag
      case 3: // Mittwoch
      case 4: // Donnerstag
      case 5: // Freitag
        open = currentTime >= 8 && currentTime < 16;
        break;
      case 6: // Samstag
      case 0: // Sonntag
        open = false;
        break;
    }
  }

  statusText.value = open ? 'Geöffnet' : 'Geschlossen';
  console.log(`Status: ${statusText.value}`);
};

const fetchHolidays = async () => {
  try {
    const response = await axios.get('https://date.nager.at/api/v3/PublicHolidays/2024/DE');
    // Filter holidays to include only those in NRW
    holidays.value = response.data
        .filter(holiday => holiday.counties && holiday.counties.includes('DE-NW'))
        .map(holiday => holiday.date);
    console.log(`Holidays: ${holidays.value}`);
  } catch (error) {
    console.error('Error fetching holidays:', error);
  }
};

onMounted(() => {
  fetchHolidays().then(() => {
    checkOpenStatus();
    setInterval(() => {
      currentDate.value = new Date();
      checkOpenStatus();
    }, 60000); // Check every minute
  });
});
</script>

<style scoped>
/* Add any required styles here */
</style>