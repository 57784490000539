<template>
  <nav :class="['bg-white w-full z-20 fixed transition-transform duration-300', { '-translate-y-full': !isVisible }]" @scroll="handleScroll">
    <div class="relative mx-auto w-full flex items-center justify-center">
      <div class="fixed sm:right-4 top-24 flex items-center space-x-4">
        <HelpDesk />
        <OpenTime />
      </div>
    </div>
    <div class="sm:max-w-5xl mx-auto h-16">
      <div class="relative flex items-center justify-between sm:h-16">
        <div class="absolute inset-y-0 right-0 flex items-center md:hidden">
          <!-- Mobile menu button-->
          <button @click="toggleMenu" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <!-- Icon when menu is closed. -->
            <svg class="block h-6 w-6 z-10" :class="{ 'hidden': menuOpen, 'block': !menuOpen }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
            <!-- Icon when menu is open. -->
            <div :class="{ 'block': menuOpen, 'hidden': !menuOpen }">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-black w-8 h-8" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
            </div>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center">
          <a href="/" class="font-semibold">
            <KwikColorLogo />
          </a>
          <div class="hidden md:block sm:ml-auto">
            <div class="flex space-x-4">
              <router-link to="/about" class="text-sm px-3 py-1 border border-slate-400 rounded-full" exact-active-class="bg-slate-800 text-white">Über uns</router-link>
              <router-link to="/service" class="text-sm px-3 py-1 border border-slate-400 rounded-full" exact-active-class="bg-slate-800 text-white">Services</router-link>
              <router-link to="/contact" class="text-sm px-3 py-1 border border-slate-400 rounded-full" exact-active-class="bg-slate-800 text-white">Kontakt</router-link>
              <!-- New button to open ProductSelectForm -->
              <button @click="showProductSelectForm" class="text-sm px-3 py-1 border border-slate-400 rounded-full bg-indigo-800 text-white">Ihre Anfrage</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden" id="mobile-menu" :class="{ 'block': menuOpen, 'hidden': !menuOpen }">
      <div class="space-y-1 w-full bg-white/90 right-0 z-50 absolute flex flex-col p-6">
        <router-link to="/about" class="text-2xl" @click="closeMenu" exact-active-class="active-link">Über uns</router-link>
        <router-link to="/service" class="text-2xl" @click="closeMenu" exact-active-class="active-link">Unser Service</router-link>
        <router-link to="/contact" class="text-2xl" @click="closeMenu" exact-active-class="active-link">Kontakt</router-link>
        <!-- New button to open ProductSelectForm -->
        <button @click="showProductSelectForm" class="text-sm px-3 py-1 border border-slate-400 rounded-full bg-indigo-800 text-white">Ihre Anfrage</button>
      </div>
    </div>

  </nav>
    <ProductSelectForm ref="productSelectForm" />
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import KwikColorLogo from "@/components/KwikColorLogo.vue";
import ProductSelectForm from "@/components/ProductSelectForm.vue";
import HelpDesk from "@/components/HelpDesk.vue";
import OpenTime from "@/components/OpenTime.vue";

const menuOpen = ref(false);
const isVisible = ref(true);
let lastScrollTop = 0;

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

const closeMenu = () => {
  menuOpen.value = false;
};

const handleScroll = () => {
  const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (currentScrollTop > lastScrollTop) {
    // Scrolling down
    isVisible.value = false;
  } else {
    // Scrolling up
    isVisible.value = true;
  }
  lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
};


const showProductSelectForm = () => {
  productSelectForm.value.showModal();
};

const productSelectForm = ref(null);

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>
.active-link {
  font-weight: bold;
  color: #007BFF; /* Change this to your preferred active link color */
}
</style>