<template>
  <div class="container mx-auto p-6">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <div
          v-for="service in services"
          :key="service.id"
          :class="[service.customClass, 'bg-white rounded-lg shadow-lg overflow-hidden']"
          v-bind="service.customAttrs"
          v-scroll-animation:up
      >
        <div v-if="service.backgroundImage" class="w-full h-48 bg-cover bg-center" :style="{ backgroundImage: `url(${service.backgroundImage})` }"></div>
        <div class="p-4">
          <h3 class="text-xl font-semibold mb-2">{{ service.name }}</h3>
          <p class="mb-4">{{ service.description }}</p>
          <a v-if="service.link" :href="service.link" class="text-blue-500 hover:text-blue-700 underline">
            {{ service.linkText || 'Learn more' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      services: []
    };
  },
  created() {
    this.fetchServices();
  },
  methods: {
    async fetchServices() {
      try {
        const response = await axios.get('services/services.json');
        this.services = response.data;
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
  }
};
</script>

<style scoped>
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
</style>