<template>
  <div :class="['flex flex-wrap', props.customClass]" :style="{ backgroundImage: `url(${props.backgroundImage})`}" class="bg-[#efefef] bg-125 md:bg-contain bg-no-repeat bg-bottom" v-bind="props.customAttrs">
    <div class="flex flex-col gap-2 items-center justify-start w-full min-h-[540px] sm:min-h-[580px]">
      <div class="max-w-xl mx-auto text-center py-10 flex flex-col items-center justify-center px-4">
        <h2 :class="props.titleClass">{{ title }}</h2>
        <p :class="props.contentClass">{{ content }}</p>
        <router-link v-if="props.link" :to="props.link" :class="props.linkButtonClass">
          {{ props.linkText }}
        </router-link>
        <button @click="requestProduct" :class="props.linkButtonClass" class="rounded-full">
          Ihre Anfrage
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  content: {
    type: String,
    required: true
  },
  backgroundImage: {
    type: String,
    required: true
  },
  linkText: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  customClass: {
    type: String,
    default: ''
  },
  customAttrs: {
    type: Object,
    default: () => ({})
  },
  titleClass: {
    type: String,
    default: 'text-3xl font-semibold mb-4'
  },
  contentClass: {
    type: String,
    default: 'text-lg mb-6'
  },
  linkButtonClass: {
    type: String,
    default: 'bg-indigo-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
  }
});

const emit = defineEmits(['requestProduct']);

const requestProduct = () => {
  emit('requestProduct', {
    id: props.id,
    name: props.title,
    description: props.content
  });
};
</script>

<style scoped>
</style>