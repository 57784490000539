import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { createMetaManager, defaultConfig } from 'vue-meta'; // Import vue-meta
import './assets/app.css';
import scrollAnimation from "@/directives/scrollAnimation";

const app = createApp(App);

//const base64FormUrl = process.env.VUE_APP_BASE_FORM_URL;
//const baseFormUrl = atob(decodeURIComponent(base64FormUrl));
// console.log('Decoded baseFormUrl:', baseFormUrl); // Verify the decoded URL
const isProduction = process.env.NODE_ENV === 'production';
console.log('Environment:', process.env.NODE_ENV);


// Conditionally load Google Analytics if in production
if (isProduction) {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-WWD26SRBBK';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-WWD26SRBBK');
    };
}


const loadProducts = async () => {
    try {
        const response = await axios.get('product-data/products.json');
        return response.data;
    } catch (error) {
        console.error('Error loading products:', error);
        return [];
    }
};

loadProducts().then(products => {
    //app.provide('baseFormUrl', baseFormUrl);
    app.provide('products', products);
    app.use(router);
    app.use(createMetaManager(defaultConfig));
    app.directive('scroll-animation', scrollAnimation);
    app.mount('#app');
});