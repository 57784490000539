<template>

  <HeroBlock
      title="Ihr Partner für kreative Ideen"
      subtitle="Schnelligkeit, Flexibilität, Maßanfertigung zu TOP Konditionen"
      ctaText=""
      ctaLink=""
      maskBlock="absolute w-full h-full top-0 left-0  bg-gradient-to-b from-slate-800/50 from-20% to-slate-500/90 to-80%"
      :mask-block-available=true
      backgroundImage="images/grafik-und-design.jpg"
      customClass="custom-hero-class"
      class="bg-cover bg-left-bottom bg-no-repeat min-h-[400px] sm:h-[440px] sm:min-h-[30vh] flex flex-col items-center justify-end text-center mb-3 text-white"
      :customAttrs="{ 'data-hero': 'imprint-hero' }"
      v-scroll-animation:down
  />

  <div class="max-w-5xl mx-auto py-8 px-6 lg:px-0">
    <h1 class="text-3xl font-bold text-gray-900 mb-4">{{ imprint.title }}</h1>
    <div v-html="imprint.content" class="text-md"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import yaml from 'js-yaml';
import axios from 'axios';
import HeroBlock from "@/components/HeroBlock.vue";

const imprint = ref({ title: '', content: '' });

const loadContent = async () => {
  try {
    const response = await axios.get('/content/imprint.yml');
    const data = yaml.load(response.data);
    imprint.value = data.imprint;
  } catch (error) {
    console.error('Error loading YAML content:', error);
  }
};

onMounted(() => {
  loadContent();
});
</script>

<style scoped>
</style>