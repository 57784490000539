<template>
  <div class="flex gap-3 items-center justify-center bg-white/70 px-4 py-2 rounded-full shadow-md">
    <div :class="dynamicClass" class="w-4 h-4 rounded-full">&nbsp;</div>
    <div class="flex items-center justify-center gap-3">
      <div>
        <div class="text-sm sm:text-lg font-bold">
          <span>HelpDesk: </span>
          <a href="tel:+49211363655">0211 36 36 55</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currentDate: new Date(),
      holidays: []
    };
  },
  computed: {
    dynamicClass() {
      const currentDay = this.currentDate.getDay();
      const currentHour = this.currentDate.getHours();
      const currentDateStr = this.currentDate.toISOString().split('T')[0];

      // Check if it's a weekend (Saturday or Sunday)
      const isWeekend = currentDay === 0 || currentDay === 6;

      // Check if it's a workday after 16:00 until next day 8:00
      const isAfterFiveOnWorkday = currentDay >= 1 && currentDay <= 5 && (currentHour >= 16 || currentHour < 8);

      // Check if it's a holiday
      const isHoliday = this.holidays.includes(currentDateStr);

      if (isWeekend || isAfterFiveOnWorkday || isHoliday) {
        return 'bg-red-500';
      } else {
        return 'bg-green-500';
      }
    },
    statusText() {
      const currentDay = this.currentDate.getDay();
      const currentHour = this.currentDate.getHours();
      const currentDateStr = this.currentDate.toISOString().split('T')[0];

      // Check if it's a weekend (Saturday or Sunday)
      const isWeekend = currentDay === 0 || currentDay === 6;

      // Check if it's a workday after 17:00
      const isAfterFiveOnWorkday = currentDay >= 1 && currentDay <= 5 && (currentHour >= 16 || currentHour < 8);

      // Check if it's a holiday
      const isHoliday = this.holidays.includes(currentDateStr);

      if (isWeekend || isAfterFiveOnWorkday || isHoliday) {
        return 'aus';
      } else {
        return 'an';
      }
    }
  },
  created() {
    // Fetch holidays when the component is created
    this.fetchHolidays();

    // Update the currentDate every minute to ensure the class is updated if the conditions change
    setInterval(() => {
      this.currentDate = new Date();
    }, 60000); // 60000 milliseconds = 1 minute
  },
  methods: {
    async fetchHolidays() {
      try {
        const response = await axios.get('https://date.nager.at/api/v3/PublicHolidays/2024/DE');
        // Filter holidays to include only those in NRW
        this.holidays = response.data
            .filter(holiday => holiday.counties && holiday.counties.includes('DE-NW'))
            .map(holiday => holiday.date);
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    }
  }
};
</script>

<style scoped>
/* You can add additional styles here if needed */
</style>