<template>
  <div v-if="isVisible" class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-yellow-400 px-6 pt-6 pb-12">
          <div @click="closeModal" class="absolute right-8 top-8 hover:cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-black w-8 h-8" viewBox="0 0 512 512">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/>
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="w-full">
              <h3 class="text-3xl leading-6 text-black uppercase font-bold py-4" id="modal-title">Anfrage</h3>
              <div class="mt-2">
                <form @submit.prevent="submitForm" class="space-y-4">
                  <div style="display:none;">
                    <label for="honeypot" class="sr-only">Honeypot:</label>
                    <input type="text" id="honeypot" v-model="honeypot" />
                  </div>
                  <div class="my-4 w-full bg-yellow-500 p-4 rounded-xl">
                    <label for="product" class="block text-xl font-medium text-gray-700 mb-2">Ausgewählte Produkt für die Anfrage:</label>
                    <input type="text" id="product" v-model="productName" readonly class="p-3 mt-1 block w-full shadow-sm text-lg rounded-md" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-lg font-medium text-gray-700">Ihr Name:</label>
                    <input type="text" id="name" v-model="name" required class="p-3 mt-1 block w-full shadow-sm sm:text-sm rounded-md" />
                  </div>
                  <div class="mb-4">
                    <label for="email" class="block text-lg font-medium text-gray-700">Ihre E-Mail Adresse:</label>
                    <input type="email" id="email" v-model="email" required class="p-3 mt-1 block w-full shadow-sm sm:text-sm rounded-md" />
                  </div>
                  <div class="mb-4">
                    <label for="message" class="block text-lg font-medium text-gray-700">Ihre Nachricht:</label>
                    <textarea id="message" v-model="message" required class="mt-1 block w-full shadow-sm sm:text-sm rounded-md p-3" rows="4"></textarea>
                  </div>
                  <div class="mb-4 bg-white/70 p-4 rounded-xl">
                    <label for="file" class="block text-lg font-medium text-gray-700">Attachment:</label>
                    <p class="text-sm py-4"><span class="font-bold">Hinweis:</span> Nur als PDF oder Zip Datei. Und Bis 2MB groß.</p>
                    <input type="file" id="file" ref="fileInput" @change="handleFileUpload" class="mt-1 block w-full shadow-sm sm:text-sm rounded-md" />
                  </div>
                  <div class="">
                    <button
                        type="submit"
                        class="bg-indigo-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Anfrage senden
                    </button>
                  </div>
                </form>
                <p v-if="successMessage" class="text-white rounded-xl px-4 py-2 my-7 bg-green-800">{{ successMessage }}</p>
                <p v-if="errorMessage" class="text-white rounded-xl px-4 py-2 my-7 bg-red-800">{{ errorMessage }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, watch, defineProps, defineEmits } from 'vue';
import axios from 'axios';

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true
  },
  product: {
    type: Object,
    required: false
  }
});

const emit = defineEmits(['close']);

const name = ref('');
const productName = ref('');
const email = ref('');
const message = ref('');
const honeypot = ref('');
const successMessage = ref('');
const errorMessage = ref('');
const file = ref(null);

watch(props, () => {
  if (props.isVisible && props.product) {
    productName.value = props.product.name;
    name.value = '';
    email.value = '';
    message.value = '';
    honeypot.value = '';
    successMessage.value = '';
    errorMessage.value = '';
    file.value = null;
  }
});

const handleFileUpload = (event) => {
  file.value = event.target.files[0];
};

const closeModal = () => {
  emit('close');
};

const submitForm = async () => {
  successMessage.value = '';
  errorMessage.value = '';

  try {
    const formData = new FormData();
    formData.append('product', productName.value);
    formData.append('name', name.value);
    formData.append('email', email.value);
    formData.append('message', message.value);
    formData.append('honeypot', honeypot.value);

    if (file.value) {
      formData.append('file', file.value);
    }

    const response = await axios.post(`https://kwik-color.de/sendEmailWithProduct.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.status === 'success') {
      successMessage.value = response.data.message;
    } else {
      errorMessage.value = response.data.message;
    }
  } catch (error) {
    errorMessage.value = 'There was an error sending your request.';
  }
};
</script>

<style scoped>
</style>