<template>
  <div id="app" class="">
    <MainNavigation />
    <router-view />
    <FooterBlock />

    <CookieConsent v-if="isProduction" />
  </div>
</template>

<script setup>
// No script needed for now
import MainNavigation from '@/components/MainNavigation.vue';
import FooterBlock from "@/components/FooterBlock.vue";
import CookieConsent from '@/components/CookieConsent.vue';

const isProduction = process.env.NODE_ENV === 'production';
// const isDevelopment = process.env.NODE_ENV === 'development';
</script>

<style scoped>
</style>