<template>
  <div v-scroll-animation:up :class="['grid grid-cols-1 md:grid-cols-2', props.customClass]" v-bind="props.customAttrs">
    <div :class="[{ 'order-1': props.imageFirst, 'order-2': !props.imageFirst }, 'w-full p-3']">
      <img :src="props.image" :alt="props.title" :class="props.imageClass" />
    </div>
    <div :class="[{ 'order-2': props.imageFirst, 'order-1': !props.imageFirst }, 'w-full']">
      <div class="p-8 sm:max-w-xl">
        <h2 :class="props.titleClass" v-scroll-animation:up>{{ props.title }}</h2>
        <p :class="props.contentClass" v-scroll-animation:up>{{ props.content }}</p>
        <router-link v-if="props.buttonOn" :to="props.link" :class="props.linkButtonClass">
          {{ props.linkText }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  content: {
    type: String,
    required: true
  },
  image: {
    type: String,
    required: true
  },
  linkText: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  imageFirst: {
    type: Boolean,
    default: true
  },
  buttonOn: {
    type: Boolean,
    default: false
  },
  customClass: {
    type: String,
    default: ''
  },
  customAttrs: {
    type: Object,
    default: () => ({})
  },
  titleClass: {
    type: String,
    default: 'text-2xl font-semibold mb-4 max-w-2xl'
  },
  contentClass: {
    type: String,
    default: 'text-xl mb-6 max-w-2xl'
  },
  imageClass: {
    type: String,
    default: 'w-full h-auto'
  },
  linkButtonClass: {
    type: String,
    default: 'bg-indigo-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
  }
});
</script>

<style scoped>
</style>