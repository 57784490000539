import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import ServicePage from "@/views/ServicePage.vue";
import ContactPage from '../views/ContactPage.vue';
import RequestForm from "@/components/RequestForm.vue";
import ImprintPage from '@/views/ImprintPage.vue';
import PrivatePolicyPage from '@/views/PrivatePolicyPage.vue';
import CookiePage from '@/views/CookiePage.vue';

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        meta: {
            title: 'Kwik Color - Willkommen',
            description: 'Schnelligkeit, Flexibilität, Maßanfertigung zu TOP Konditionen',
        },
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: AboutPage,
        meta: {
            title: 'Kwik Color - Über uns',
            description: 'Unsere Druckerei bietet Ihnen herausragende Dienstleistungen, die sich durch Geschwindigkeit, Flexibilität und maßgeschneiderte Lösungen auszeichnen. Ob Offsetdruck, Digitaldruck, Plakate, Buchbindungen oder Kopien.',
        },
    },
    {
        path: '/service',
        name: 'ServicePage',
        component: ServicePage,
        meta: {
            title: 'Kwik Color - Unser Service',
            description: 'Unsere Druckerei bietet Ihnen herausragende Dienstleistungen, die sich durch Geschwindigkeit, Flexibilität und maßgeschneiderte Lösungen auszeichnen. Ob Offsetdruck, Digitaldruck, Plakate, Buchbindungen oder Kopien.',
        },
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: ContactPage,
        meta: {
            title: 'Kwik Color - Kontakt',
            description: 'Ihr Partner für kreative Ideen',
        },
    },
    {
        path: '/request/:productName',
        name: 'Request',
        component: RequestForm,
        props: true,
    },
    { path: '/imprint',
        component: ImprintPage,
        meta: {
            title: 'Kwik Color - Impressum',
            description: 'Ihr Partner für kreative Ideen',
        },
    },
    { path: '/private-policy',
        component: PrivatePolicyPage,
        meta: {
            title: 'Kwik Color - Datenschutz',
            description: 'Ihr Partner für kreative Ideen',
        },
    },
    { path: '/cookie-policy',
        component: CookiePage,
        meta: {
            title: 'Kwik Color - Cookie Richtlinie',
            description: 'Ihr Partner für kreative Ideen',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


router.beforeEach((to, from, next) => {
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithMeta) {
        document.title = nearestWithMeta.meta.title;
    }
    const nearestWithMetaDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);
    if (nearestWithMetaDescription) {
        const descriptionTag = document.querySelector('meta[name="description"]');
        if (descriptionTag) {
            descriptionTag.setAttribute('content', nearestWithMetaDescription.meta.description);
        } else {
            const metaTag = document.createElement('meta');
            metaTag.setAttribute('name', 'description');
            metaTag.setAttribute('content', nearestWithMetaDescription.meta.description);
            document.head.appendChild(metaTag);
        }
    }
    next();
});


export default router;