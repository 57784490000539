<template>
  <div :class="['py-20 relative', props.customClass]" :style="{ backgroundImage: `url(${props.backgroundImage})` }" v-bind="props.customAttrs">
    <div v-if="props.maskBlockAvailable" :class="maskBlock"></div>
    <img :src="printSlang" v-if="props.printSlangAvailable" alt="" class="absolute top-44 sm:top-[200px] sm:right-12 w-[400px] sm:w-[40vw] z-20 px-6 mb-12" v-scroll-animation:up />
    <div class="max-w-4xl mx-auto px-4 z-10 relative">
      <h1 :class="props.titleClass">{{ props.title }}</h1>
      <p :class="props.subtitleClass">{{ props.subtitle }}</p>
      <router-link v-if="props.ctaLink" :to="props.ctaLink" :class="props.ctaButtonClass">
        {{ props.ctaText }}
      </router-link>
      <a v-if="ctaCall" :href="props.ctaCall" :class="props.ctaButtonClass">{{ props.ctaText }}</a>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: true
  },
  maskBlock: {
    type: String,
    default: ''
  },
  maskBlockAvailable: {
    type: Boolean,
    default: false
  },
  printSlang: {
    type: String,
    default: ''
  },
  printSlangAvailable: {
    type: Boolean,
    default: false
  },
  ctaText: {
    type: String,
    required: true
  },
  ctaLink: {
    type: String,
    required: true
  },
  ctaCall: {
    type: String,
    required: true
  },
  backgroundImage: {
    type: String,
    default: ''
  },
  customClass: {
    type: String,
    default: ''
  },
  customAttrs: {
    type: Object,
    default: () => ({})
  },
  titleClass: {
    type: String,
    default: 'text-3xl sm:text-4xl font-semibold mb-4'
  },
  subtitleClass: {
    type: String,
    default: 'text-xl sm:text-2xl mb-6'
  },
  ctaButtonClass: {
    type: String,
    default: 'border border-white text-white font-bold py-2 px-4 rounded-full'
  }
});
</script>

<style scoped>
</style>