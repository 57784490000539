<template>

    <HeroBlock
        title="Ihr Partner für kreative Ideen"
        subtitle="Schnelligkeit, Flexibilität, Maßanfertigung zu TOP Konditionen"
        ctaText="Kontakt aufnehmen"
        cta-call=""
        ctaLink="/contact"
        maskBlock="absolute w-full h-full top-0 left-0 z-10 bg-gradient-to-b from-indigo-700/70 from-20% to-indigo-950/90 to-80%"
        :mask-block-available=true
        :print-slang-available=true
        print-slang="images/ichdruckdich.svg"
        backgroundImage="images/paarflugz-unscharf-frei.png"
        customClass="custom-hero-class"
        class="bg-cover sm:bg-contain bg-center sm:bg-left-bottom bg-no-repeat min-h-[680px] sm:h-[600px] sm:min-h-[60vh] flex flex-col items-center justify-end text-center mb-3 text-white"
        :customAttrs="{ 'data-hero': 'main-hero' }"
        v-scroll-animation:down
    />

    <HeroBlock
        title="Top Angebot"
        subtitle="Starten Sie Ihre Pizzaflyer Aktion schneller mit uns!"
        ctaText="0211 36 36 55"
        ctaLink=""
        cta-call="tel:+49211363655"
        maskBlock="absolute w-full h-full top-0 left-0 z-10 bg-gradient-to-b from-gray-800/50 from-20% to-red-800/90 to-80%"
        :mask-block-available=true
        backgroundImage="images/AdobeStock_248649710_small.jpg"
        customClass="custom-hero-class"
        class="bg-cover bg-center bg-no-repeat min-h-[280px] sm:h-[300px] sm:min-h-[30vh] flex flex-col items-center justify-end text-center mb-3 text-white"
        :customAttrs="{ 'data-hero': 'main-hero' }"
        v-scroll-animation:up
    />



  <div class="w-full mx-auto mb-3">
    <div class="grid grid-cols-1 lg:grid-cols-2 xxl:grid-cols-3 gap-3 px-3">
      <div v-for="product in products" :key="product.id">
        <ProductItem
            :title="product.name"
            :content="product.description"
            :backgroundImage="product.backgroundImage"
            :linkText="product.linkText"
            :link="product.link"
            :customClass="product.customClass"
            :customAttrs="product.customAttrs"
            @requestProduct="openRequestModal"
            v-scroll-animation:up
        />
      </div>
    </div>
    <ModalRequestForm :product="selectedProduct" :isVisible="isModalVisible" @close="closeRequestModal" />
  </div>

</template>

<script setup>

import { ref, inject } from 'vue';
import ProductItem from '@/components/ProductItem.vue';
import ModalRequestForm from '@/components/ModalRequestForm.vue';
import HeroBlock from '@/components/HeroBlock.vue';

const products = inject('products');
const selectedProduct = ref(null);
const isModalVisible = ref(false);

const openRequestModal = (product) => {
  selectedProduct.value = product;
  isModalVisible.value = true;
};

const closeRequestModal = () => {
  isModalVisible.value = false;
  selectedProduct.value = null;
};

</script>

<style scoped>
</style>