<template>
  <div class="max-w-lg mx-auto py-6">
    <h1 class="text-2xl font-bold mb-4">Request Product</h1>
    <form @submit.prevent="submitForm" class="space-y-4">
      <div style="display:none;">
        <label for="honeypot" class="sr-only">Honeypot:</label>
        <input type="text" id="honeypot" v-model="honeypot" />
      </div>
      <div class="mb-4">
        <label for="product" class="block text-sm font-medium text-gray-700">Product:</label>
        <input type="text" id="product" v-model="product" readonly class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>
      <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-gray-700">Name:</label>
        <input type="text" id="name" v-model="name" required class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700">Email:</label>
        <input type="email" id="email" v-model="email" required class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>
      <div class="mb-4">
        <label for="message" class="block text-sm font-medium text-gray-700">Message:</label>
        <textarea id="message" v-model="message" required class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
      </div>
      <div class="mb-4">
        <label for="file" class="block text-sm font-medium text-gray-700">Attachment:</label>
        <input type="file" id="file" ref="fileInput" @change="handleFileUpload" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>
      <div class="flex justify-end">
        <button type="submit" class="bg-indigo-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Send Request
        </button>
      </div>
    </form>
    <p v-if="successMessage" class="text-green-500 mt-4">{{ successMessage }}</p>
    <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import axios from 'axios';
import {useRoute} from 'vue-router';

const route = useRoute();
const product = ref(route.params.productName || '');
const name = ref('');
const email = ref('');
const message = ref('');
const honeypot = ref(''); // Honeypot field
const successMessage = ref('');
const errorMessage = ref('');
const file = ref(null);

const handleFileUpload = (event) => {
  file.value = event.target.files[0];
};

const submitForm = async () => {
  successMessage.value = '';
  errorMessage.value = '';

  try {
    const formData = new FormData();
    formData.append('product', product.value);
    formData.append('name', name.value);
    formData.append('email', email.value);
    formData.append('message', message.value);
    formData.append('honeypot', honeypot.value); // Add honeypot field

    if (file.value) {
      formData.append('file', file.value);
    }

    const response = await axios.post('https://werkkasten.com/sendEmailWithProduct.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.status === 'success') {
      successMessage.value = response.data.message;
    } else {
      errorMessage.value = response.data.message;
    }
  } catch (error) {
    errorMessage.value = 'There was an error sending your request.';
  }
};
</script>

<style scoped>
</style>